import React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Button from '@material-ui/core/Button';
import DashboardIcon from '@material-ui/icons/Dashboard';
import classNames from 'classnames';


function createData(dname, min, avg, max, ui) {
  return { dname, min, avg, max, ui };
}



export default function DenseTable(props) {

  if (props.readyness !== false && props.dataSetSum !== undefined) {
    let avgQuery = 'summary_avg_'+ props.dataType
    if (props.dataSetSum[0].summary_stats[avgQuery] === null) {
      return (<div style={{display: 'flex', justifyContent:'center', alignItems:'center', color:'#fff'}}></div>)
    } else {
      //remove all summary datasets from the api return call
      // we only need to datasets that belong to the devices for the table
      delete props.dataSetSum[0].summary_stats['summary_min_' + props.dataType]
      delete props.dataSetSum[0].summary_stats['summary_min_' + props.dataType + '_name']
      delete props.dataSetSum[0].summary_stats['summary_min_' + props.dataType + '_timestamp']
      delete props.dataSetSum[0].summary_stats['summary_max_' + props.dataType]
      delete props.dataSetSum[0].summary_stats['summary_max_' + props.dataType + '_name']
      delete props.dataSetSum[0].summary_stats['summary_max_' + props.dataType + '_timestamp']
      delete props.dataSetSum[0].summary_stats['summary_avg_' + props.dataType]
      var dataSet = props.dataSetSum[0].summary_stats;
      var rows = []
      for (var property in dataSet) {
        if (dataSet.hasOwnProperty(property)) {
            //use the property object to fetch dataSet
            let data = dataSet[property]
            let minRow = data['summary_min_per_device_' + props.dataType]
            let avgRow = data['summary_avg_per_device_' + props.dataType]
            let maxRow = data['summary_max_per_device_' + props.dataType]
            let pk = data['pk']
            let dashboardPKQuery = {id: pk, text: property}
            rows.push(createData(property, parseFloat(minRow).toFixed(2),
            parseFloat(avgRow).toFixed(2), parseFloat(maxRow).toFixed(2),
            <Button variant="contained" onClick={() => props.atChange(dashboardPKQuery)}
            size="small" className={props.fab}>
            <DashboardIcon className={classNames(props.leftIcon, props.iconSmall)}/>
            </Button>))
        }
      }


  return (
    <div className={props.root}>
    <div style={{width: 'auto', overflowX: 'scroll'}}>
        <Table className={props.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell>Device name</TableCell>
              <TableCell align="right">Min. {props.dataType}</TableCell>
              <TableCell align="right">Avg. {props.dataType}</TableCell>
              <TableCell align="right">Max. {props.dataType}</TableCell>
              <TableCell align="right">UI</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <TableRow key={row.dname}>
                <TableCell component="th" scope="row">
                  {row.dname}
                </TableCell>
                <TableCell align="right">{row.min}</TableCell>
                <TableCell align="right">{row.avg}</TableCell>
                <TableCell align="right">{row.max}</TableCell>
                <TableCell align="right">{row.ui}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}
}
else {
  return (<div style={{display: 'flex',  justifyContent:'center', alignItems:'center',  color:'#fff'}}></div>)
}
}
