import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import moment from 'moment'
import ScatterChart from 'recharts/lib/chart/ScatterChart';
import Scatter from 'recharts/lib/cartesian/Scatter'
import CircularProgress from '@material-ui/core/CircularProgress';
// recharts doesn't export the default tooltip,
// but it's located in the package lib so you can get to it anyways
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';


const CustomTooltip = props => {
  // we don't need to check payload[0] as there's a better prop for this purpose
  if (!props.active) {
    // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
    return null
  }
  // mutating props directly is against react's conventions
  // so we create a new payload with the name and value fields set to what we want
  const newPayload = [{
      name: 'Image taken date:',
      // all your data which created the tooltip is located in the .payload property
      value: props.payload[0].payload.img_taken_date,
      // you can also add "unit" here if you need it
    },

    ...props.payload,
  ];
  // we render the default, but with our overridden payload
  return <DefaultTooltipContent {...props} payload={newPayload} />;
};




function renameKeys(arr, nameMap) {
  // loop around our array of objects
  for (var i = 0; i < arr.length; i++) {
    var obj = arr[i];
    // loop around our name mappings
    for (var j = 0; j < nameMap.length; j++) {
      var thisMap = nameMap[j];
      if (obj.hasOwnProperty(thisMap.from)) {
        // found matching name
        obj[thisMap.to] = obj[thisMap.from];
        delete obj[thisMap.from];
      }
    }
  }
}

function SummaryNDVIChart(props) {

  const {
    classes
  } = props;

  // verify if the graph is ready to render (in case data is available)
  if (props.readyness !== false && props.dataSetSum !== undefined) {
    var formattedData = []
    const deviceNames = []
    var finalData = []
    const series = []
    const pallette = ["#96ece3",
      "#eaaecf",
      "#9dc58e",
      "#74aff3",
      "#d8ebb4",
      "#c4b7ea",
      "#aee9c3",
      "#e9b198",
      "#5ecee9",
      "#d3c896",
      "#9cc6ed",
      "#92c4a6",
      "#70cacd",
      "#86bcb1",
      "#8ed4c6"
    ]
    // get all keys (e.g. device1, device2)
    // we use the keys to retrieve the data (property is the index pos)
    for (var property in props.dataSetSum[0].measurement_data) {
      if (props.dataSetSum[0].measurement_data.hasOwnProperty(property)) {

        var fullDataSet = props.dataSetSum[0].measurement_data[property]
        // check if we don't have empty data
        if (Object.entries(fullDataSet).length > 0) {
          deviceNames.push(property)

          // from the fullDataset rename ndvi_mean parameter with device name
          renameKeys(fullDataSet, [{
            from: props.dataType + "_mean",
            to: property + "_" + props.dataType + "_mean"
          }]);
          // push to the formattedData array the raw elements named as el
          fullDataSet.map(el => formattedData.push(el))
          // quick fix for datapoints api (recharts doesnt auto-handle dates)
          if (props.callType === 'datapoints') {
            finalData = Object.assign([], formattedData).reverse();
            delete formattedData.obj;
          } else {
            finalData = formattedData;
            delete formattedData.obj;
          }
          series.push({
            name: property,
            data: finalData
          })
        }
        // if dataset is empty because there is no data omit.
      }
    }

    // check if the formattedData contains data
    // Also check if the maximum number of support devices for showing the graph
    if (formattedData.length > 0 && deviceNames.length < 5) {
      delete fullDataSet.obj;


      return (
        <div>


<ResponsiveContainer width="99%" height={320}>
<ScatterChart width={600} height={300}>
  <CartesianGrid vertical={false} strokeDasharray="3 3"/>
  <XAxis dataKey="comparative_date" type="category" allowDuplicatedCategory={false}  tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm Do')}/>
  <YAxis type="number" domain={['auto', 'auto']}/>
  <Tooltip
      content={<CustomTooltip />}
     labelStyle={{ color: "#7d97ce" }}
     itemStyle={{ color: "#7d97ce" }}
     formatter={function(value, name) {
       return `${value}`;
     }}
     labelFormatter={function(value) {
       return `label: ${value}`;
     }}
/>
  <Legend />
  {series.map((s, idx) => (
    <Scatter dataKey={`${s.name+'_'+props.dataType}_mean`} fill={pallette[idx]} stroke={'black'}  data={s.data} name={s.name} key={s.name} />
  ))}
</ScatterChart>
</ResponsiveContainer>
</div>
      );

    } else {
      return (<div>Requested data is not available.</div>)
    }


  } else {
    return (<div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
       <CircularProgress className={classes.progress} color="secondary" /><span>&nbsp;&nbsp;</span> Loading...</div>)
  }
}

export default SummaryNDVIChart;
