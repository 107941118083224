import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles';
import history from '../history';
import SignIn from './SignUpOrSignIn';
import {
  auth
} from "../actions";
import ButtonAppBar from "./NavBar";

const styles = theme => ({
  "@global": {
    body: {
      backgroundImage: "url('/static/pics/Optimized-_DSC8102.png')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      height: "100%"
    },
    html: {
      height: "100%"
    },
    "#componentWithId": {
      height: "100%"
    }
  }
});
const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      light: '#7d97ce',
      main: '#00043d',
      dark: '#5b79bb',
      contrastText: '#fff',
    },
  },
  typography: {
    useNextVariants: true
  },
});

class Login extends Component {
  constructor(props) {
    super(props)

    this.stateHandlerUser = this.stateHandlerUser.bind(this)
    this.stateHandlerPwd = this.stateHandlerPwd.bind(this)
  }

  componentDidUpdate() {
    if (this.props.isAuthenticated) {
      history.push({
        pathname: "/"
      })
    }
  }

  state = {
    username: "",
    password: ""
  }

  onSubmit = e => {
    e.preventDefault();
    this.props.login(this.state.username, this.state.password);
  }

  stateHandlerUser(retrieved_user) {
    this.setState({
      username: retrieved_user
    })
  }

  stateHandlerPwd(retrieved_pwd) {

    this.setState({
      password: retrieved_pwd
    })
  }


  render() {

    return (

      <div>
      <MuiThemeProvider theme={theme}>

      <ButtonAppBar/>
      <SignIn login={'login'} onSubmit={this.onSubmit} errors={this.props.errors} atChangeUser={this.stateHandlerUser.bind(this)} atChangePwd={this.stateHandlerPwd.bind(this)}></SignIn>

</MuiThemeProvider>
    </div>
    )
  }
}

const mapStateToProps = state => {
  let errors = [];
  if (state.auth.errors) {
    errors = Object.keys(state.auth.errors).map(field => {

      return {
        field,
        message: state.auth.errors[field]
      };
    });
  }
  return {
    errors,
    isAuthenticated: state.auth.isAuthenticated
  };
}

const mapDispatchToProps = dispatch => {
  return {
    login: (username, password) => {
      return dispatch(auth.login(username, password));
    }
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Login));
