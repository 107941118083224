import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';


export default function CheckboxesGroup(props) {
  const {
    classes
  } = props;
  const [state, setState] = React.useState({
    datapoints: true,
    day: false,
    analytic: false,
  });

  const handleChange = (name, disableName, disableName2) => event => {
      setState({ ...state, [name]: event.target.checked, [disableName]: !event.target.checked,
         [disableName2]: !event.target.checked  });
      props.atChange(name)

    //setState({ ...state, [name]: event.target.checked });
    //console.log(event.target.checked);
  };

  const { datapoints, day, analytic } = state;

  return (
    <div className={classes.root} style={{
        color: '#fff', textAlign: 'center'
      }}>
    <p style={{
        color: '#fff', textAlign: 'center'
      }}>Pick your data type : </p>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Radio checked={datapoints} disabled={datapoints} onChange={handleChange('datapoints', 'day', 'analytic')} value="datapoints"
            style= {
          {
            color: {datapoints} ? '#5b79bb' : '#7d97ce',
          }}/>}
            label="Datapoints"
          />
        </FormGroup>
      </FormControl>
      <FormControl required component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Radio checked={day} disabled={day} onChange={handleChange('day', 'datapoints', 'analytic')} value="day"
            style= {
          {
            color: {day} ? '#5b79bb' : '#7d97ce',

          }}/>}
            label="Day"
          />

        </FormGroup>
      </FormControl>
      <FormControl required component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Radio checked={analytic} disabled={analytic} onChange={handleChange('analytic', 'datapoints', 'day')} value="analytic"
            style= {
          {
            color: {analytic} ? '#5b79bb' : '#7d97ce',

          }}/>}
            label="Historical"
          />

        </FormGroup>
      </FormControl>
    </div>
  );
}
