const initialState = {data: [], isLoaded: false};


export default function measurements(state=initialState, action) {

  switch (action.type) {

    default:
      return state;

  case 'FETCH_MEASUREMENTS':
    return {data: [...action.measurements], isLoaded:true};

    case 'FETCH_MEASUREMENTS_ALL':
      return {data: [action.measurementsAll],
         isLoaded:true};

    case 'RESET_DATASETS':
      return initialState;

  }
}
