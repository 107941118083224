const initialState = {dataChl: [], isLoaded: false};

export default function measurementsChl(state=initialState, action) {

  switch (action.type) {

    default:
      return state;

case 'FETCH_MEASUREMENTS_CHL':
  return {dataChl: [action.measurementsChl],
     isLoaded:true};
     case 'RESET_DATASETS_CHL':
       return initialState;

   }
 }
