import React, {
  Component
} from 'react';

import {
  connect
} from 'react-redux';
import {
  devices,
  auth
} from "../actions";
import ButtonAppBar from "./NavBar";
import FloatingActionButtons from "./Buttons";
//import FormControl from '@material-ui/core/FormControl';
//import InputLabel from '@material-ui/core/InputLabel';
//import FilledInput from '@material-ui/core/FilledInput';
import Grid from '@material-ui/core/Grid';
import Helmet from 'react-helmet';
import CustomDrawer from './dashboardNav';
import mainLogo from '../pics/g12.png';
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import history from '../history';
import CardContent from '@material-ui/core/CardContent';
import CircularProgress from '@material-ui/core/CircularProgress';
import Typography from '@material-ui/core/Typography';
const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  formControl: {
    margin: theme.spacing.unit
  },
  card: {
    minWidth: 275
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)'
  },
  title: {
    marginBottom: 16,
    fontSize: 14
  },
  pos: {
    marginBottom: 12
  },
  "@global": {
    body: {
      backgroundImage: "url('/static/pics/Optimized-_DSC8113.png')",
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center center",
      backgroundSize: "cover",
      backgroundAttachment: "fixed",
      height: "100%"
    },
    html: {
      height: "100%"
    },
    "#componentWithId": {
      height: "100%"
    }
  }
});

const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      light: '#7d97ce',
      main: '#00043d',
      dark: '#5b79bb',
      contrastText: '#fff'
    }
  },
  typography: {
    useNextVariants: true
  }
});

const text = {
  color: '#D5D5D5'
}

class MainApp extends Component {
  constructor(props) {
    super(props)
    this.closeFunc = this.closeFunc.bind(this)
    this.state = {
      text: "",
      updateDeviceId: null,
      showNav: false,
      selectedDevice: null
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    // determine if its the first time entering this page (upon login)
    if (this.props.history.action === "PUSH" || nextProps.history.action === "PUSH") {
      if (nextProps.devices.length === 1) {
        history.push({
          pathname: "/dashboard",
          deviceId: nextProps.devices[0],
          user: this.props.user,
          logout: this.props.logout
        })
        return true
      } else if (nextProps.devices.length > 1) { // if the user want to enter a specific device id dashboard
        if (nextState.selectedDevice) {
          return true
        } else { // if there are multiple devices we want to automatic forwards to summary page
          let deviceIds = []
          if (this.props.devices.length > 5) {
            deviceIds = this.props.devices.slice(1,6)
          } else {
            deviceIds = this.props.devices
          }

          history.push({
            pathname: "/summary",
            user: this.props.user,
            logout: this.props.logout,
            devices: this.props.devices,
            allowedDevices: deviceIds
          })
          return true
        }
      } else { // incase the devices are not loaded yet
        return false
      }
    } else { // if somebody pressed the manage devices button
      return true
    }


  }


  componentDidMount() {
    // first check if the fetchDevices is not called yet
    if (this.props.devices.length === 0) {
      this.props.fetchDevices();
    }




  }

  resetForm = () => {
    this.setState({
      text: "",
      updateDeviceId: null
    });
  }
  infoForm = (id) => {
    let device = this.props.devices[id];
    // set the state for the selectedDevice id
    this.setState({
      selectedDevice: device
    })
    // show the dashboard (not necessary at the moment)
    // this.setState({showNav: true});

    history.push({
      pathname: "/dashboard",
      deviceId: device,
      user: this.props.user,
      logout: this.props.logout,
      devices: this.props.devices
    })

  }
  summaryForm = () => {
    let deviceIds = []
    if (this.props.devices.length > 5) {
      deviceIds = this.props.devices.slice(1,6)
    } else {
      deviceIds = this.props.devices
    }
    history.push({
      pathname: "/summary",
      user: this.props.user,
      logout: this.props.logout,
      devices: this.props.devices,
      allowedDevices: deviceIds
    })

  }

  closeFunc(param) {
    this.setState({
      showNav: param
    });
  }

  selectForEdit = (id) => {
    let device = this.props.devices[id];
    this.setState({
      text: device.text,
      updateDeviceId: id
    });
  }

  submitDevice = (e) => {
    e.preventDefault();
    if (this.state.updateDeviceId === null) {
      this.props.addDevice(this.state.text).then(this.resetForm);
    } else {
      this.props.updateDevice(this.state.updateDeviceId, this.state.text).then(this.resetForm);
    }
  }

  render() {

    return (<div >
      <MuiThemeProvider theme={theme}>
        <Helmet bodyAttributes={{
            style: 'background-color : #303030'
          }}/>

        <ButtonAppBar user={this.props.user.username} logout={this.props.logout}/> {
          this.state.showNav
            ? <CustomDrawer open={this.state.showNav} deviceId={this.state.selectedDevice} user={this.props.user.username} logout={this.props.logout} closeFunc={this.closeFunc}/>
            : null
        }
        <br/>

        <Grid container spacing={0} direction="column" alignItems="center" justify="center">

          <Grid item md={8} style={{
              minWidth: '30%'
            }}>
            <Card style={{
                minHeight: '20vw',
                maxHeight: '90vw',
                overflow: 'auto'
              }}>
              <CardContent>
                <Typography component="h1" variant="h5" style={{
                    textAlign: 'center'
                  }}>
                  <img style={{
                      height: 40,
                      width: 40
                    }} src={mainLogo} alt="#"/><br/>
                  Manage Devices
                  <hr/>
                </Typography>
                {
                  this.props.devices.length === 0
                    ? <div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                       <CircularProgress className={this.props.progress} color="secondary" /><span>&nbsp;&nbsp;</span> </div>
                    : <div>
                    <table >
                        <tbody>
                          {
                            this.props.devices.map((device, id) => (<tr key={`device_${id}`}>
                              <td style={text}>
                                {device.text.replace("_", " ")}</td>
                              {/*
              <td>
                <FloatingActionButtons edit={'edit'} editFunc={() => this.selectForEdit(id)}/>
              </td>
              <td>
                <FloatingActionButtons delete={'delete'} deleteFunc={() => this.props.deleteDevice(id)}/>
              </td>
              <td>
                <FloatingActionButtons reset={'reset'} resetFunc={this.resetForm}/>
              </td>
              */
                              }
                              {
                                device.text
                                  ? <td>
                                      <FloatingActionButtons info={'info'} infoFunc={() => this.infoForm(id)}/>
                                    </td>
                                  : null
                              }
                            </tr>))
                          }
                        </tbody>

                      </table>
                      <FloatingActionButtons summary={'summary'} summaryFunc={() => this.summaryForm()}/>

                      </div>
                }
                {/*
      <form onSubmit={this.submitDevice}>
        <FormControl onSubmit={this.submitDevice} className={styles.formControl} variant="filled">
          <InputLabel htmlFor="component-filled">Enter new device name...</InputLabel>
          <FilledInput id="component-helper" value={this.state.text} onChange={(e) => this.setState({text: e.target.value})} aria-describedby="component-helper-text"/>

        </FormControl>

        <FloatingActionButtons save={'save'} type={'submit'}/>
      </form>
      */
                }

              </CardContent>

            </Card>
          </Grid>
        </Grid>

      </MuiThemeProvider>
    </div>)
  }
}

/* used to "map" the application state to the "props" of the component */
const mapStateToProps = state => {
  return {
    devices: state.devices,
    user: state.auth.user
  }
}

/* "mapping" action dispatcher functions to component "props". */
const mapDispatchToProps = dispatch => {
  return {
    fetchDevices: () => {
      dispatch(devices.fetchDevices());
    },
    addDevice: (text) => {
      return dispatch(devices.addDevice(text));
    },
    updateDevice: (id, text) => {
      return dispatch(devices.updateDevice(id, text));
    },
    deleteDevice: (id) => {
      dispatch(devices.deleteDevice(id));
    },
    logout: () => dispatch(auth.logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(MainApp));
