import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
  withStyles
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import StepSlider from './dashboardSlider';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },

});

class DialogSelect extends React.Component {
  state = {
    open: false,
    callType: 'datapoints',
    callValue: '1',
    max: this.props.limit
  };

  handleChange = name => event => {
    this.setState({
      [name]: Number(event.target.value)
    });
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleOk = () => {
    this.props.atChange(this.state.callType, this.state.callValue)
    this.setState({
      open: false
    });
  };

  handleSlider(call_type, call_value) {
    this.setState({
      callType: call_type,
      callValue: call_value
    })
  }
  manualInput(event) {
    let tmpValue = event.target.value;
    if (tmpValue >= 1 && tmpValue <= this.state.max) {
      this.setState({
        callType: 'datapoints',
        callValue: event.target.value
      })
    }


  }
  addKeyHandler(){
    this.props.atChange(this.state.callType, this.state.callValue)
    this.setState({
      open: false
    });
  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <div>
        <Button onClick={this.handleClickOpen} variant="contained" color="primary" size="small" disabled={!this.props.disable}>Select datapoints</Button>
        <Dialog

          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={this.handleClose}
        >
          <DialogTitle>Select the number of datapoints<hr /></DialogTitle>

          <DialogContent>
          <Typography  id="label">Current Selection: {this.state.callValue}</Typography>

            <form >
            <TextField
             id="standard-number"
             label="Number"
             onKeyPress={(ev) => {
               if (ev.key === 'Enter') {
                 // Do code here
                 ev.preventDefault();
                 this.addKeyHandler();
               }
             }}
             inputProps={{ min: "1", max: this.state.max, step: "1" }}
             value={this.state.callValue}
             onChange={this.manualInput.bind(this)}
             type="number"
             className={classes.textField}
             InputLabelProps={{
               shrink: true,
             }}
             margin="normal"
            />
            <br />
            <br />
            <br />
              <StepSlider color="secondary" atChange={this.handleSlider.bind(this)} limit={this.state.max}/>

            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
            <Button onClick={this.handleOk} color="secondary">
              Ok
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

DialogSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(DialogSelect);
