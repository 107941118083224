import React from 'react';
import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
import AreaChart from 'recharts/lib/chart/AreaChart';
import Area from 'recharts/lib/cartesian/Area';
import XAxis from 'recharts/lib/cartesian/XAxis';
import YAxis from 'recharts/lib/cartesian/YAxis';
import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
import Tooltip from 'recharts/lib/component/Tooltip';
import Legend from 'recharts/lib/component/Legend';
import moment from 'moment'
import CircularProgress from '@material-ui/core/CircularProgress';
// recharts doesn't export the default tooltip,
// but it's located in the package lib so you can get to it anyways
import DefaultTooltipContent from 'recharts/lib/component/DefaultTooltipContent';


const CustomTooltip = props => {
  // we don't need to check payload[0] as there's a better prop for this purpose
  if (!props.active) {
    // I think returning null works based on this: http://recharts.org/en-US/examples/CustomContentOfTooltip
    return null
  }
  let ndvi_date = props.payload[0].payload.img_taken_date
  let chl_obj = props.chlDataSet.dataChl[0].find(chl => chl.img_taken_date === ndvi_date)
  // mutating props directly is against react's conventions
  // so we create a new payload with the name and value fields set to what we want
  const newPayload = [{
      name: 'Chlorophyll_mean:',
      value: chl_obj.chl_mean
      // all your data which created the tooltip is located in the .payload property
      // (custom) here we are looking for the matching chl date object
    },

    ...props.payload,
  ];


  // we render the default, but with our overridden payload
  return <DefaultTooltipContent {...props} payload={newPayload} />;
};

function NDVIChart(props) {
  const {
    classes
  } = props;


  // the property is received and the dataset is there for both chl as ndvi
  if (props.readyness !== false && props.dataSet.data !== undefined && props.dataSet.data.length > 0 &&
    props.chlDataSet !== undefined && props.chlReadyness !== false && props.chlDataSet.dataChl[0].length > 0) {

    // concatenate the two datasets
    let data = props.dataSet.data.concat(props.chlDataSet.dataChl[0])

    return (

      // 99% per https://github.com/recharts/recharts/issues/172
      <ResponsiveContainer width="99%" height={320} >
      <AreaChart data={data} >
      <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <XAxis dataKey="img_taken_date" tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm Do')} allowDuplicatedCategory={false}/>
        <YAxis yAxisId="left" dx={5} tickFormatter={value => parseFloat(value).toFixed(1)} label={{ value: 'NDVI', angle: 270, offset:20, stroke:'#7d97ce', fill:'#7d97ce'}} />
        <YAxis yAxisId="right" tickFormatter={value => parseFloat(value).toFixed(0)} orientation="right" label={{ value: 'Chlorophyll content', angle: -90,  offset:20, stroke:'#97ce7d', fill:'#97ce7d' }}  />
        <Tooltip
        content={<CustomTooltip chlDataSet={props.chlDataSet}/>}
           labelStyle={{ color: "#7d97ce" }}
           itemStyle={{ color: "#7d97ce" }}
           formatter={function(value, name) {
             return `${value}`;
           }}
           labelFormatter={function(value) {
             return `label: ${value}`;
           }}
         />
        <Legend />
        <Area yAxisId="left"  type="linear" dataKey="ndvi_mean" fill="#5b79bb" stroke="#7d97ce" dot={false} activeDot={{ r: 8 }} />
        <Area yAxisId="right" type="linear" dataKey="chl_mean" fill="#97ce7d" stroke="#5a7b4b" dot={false} activeDot={{ r: 8 }} />
      </AreaChart>
    </ResponsiveContainer>

    );
  }
  // the property is received and the dataset is there
  else if (props.readyness !== false && props.dataSet.data !== undefined && props.dataSet.data.length > 0) {

    return (

      // 99% per https://github.com/recharts/recharts/issues/172
      <ResponsiveContainer width="99%" height={320}>
      <AreaChart data={props.dataSet.data} >
        <XAxis dataKey="img_taken_date" tickFormatter = {(unixTime) => moment(unixTime).format('HH:mm Do')} />
        <YAxis   tickFormatter={value => parseFloat(value).toFixed(1)} type="number" domain={['auto', 'auto']} dx={5} label={{ value: 'NDVI', angle: 270, offset:20, stroke:'#7d97ce', fill:'#7d97ce'}} />
        <CartesianGrid vertical={false} strokeDasharray="3 3" />
        <Tooltip

           labelStyle={{ color: "#7d97ce" }}
           itemStyle={{ color: "#7d97ce" }}
           formatter={function(value, name) {
             return `${value}`;
           }}
           labelFormatter={function(value) {
             return `label: ${value}`;
           }}
         />
        <Legend />
        <Area type="monotone" dataKey="ndvi_mean" fill="#7d97ce" stroke="#5b79bb" dot={false} activeDot={{ r: 8 }} />
      </AreaChart>
    </ResponsiveContainer>

    );
  }


  // the property is received and the API has responded with an empty dataset
  else if (props.readyness !== false && props.dataSet.data !== undefined && props.dataSet.data.length === 0 &&
    props.chlReadyness !== false) {
    return (<div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>No data available. Please verify that your device is running.</div>);
  }
  // the property is not there yet, the API also has not responded
  else {
    return (<div style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>

   <CircularProgress className={classes.progress} color="secondary" /> <span>&nbsp;&nbsp;</span> Loading...</div>)
  }
}
export default NDVIChart;
