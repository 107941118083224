import React from 'react';
import PropTypes from 'prop-types';
import Button from '@material-ui/core/Button';
import {
  withStyles
} from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TransferList from './transferList';
import CheckboxesGroup from './summaryPickQueryType';
import ControlledOpenSelect from "./ndviChartDropdown";
import DialogSelect from "./dashboardAdvancedDialog";
import DatePickers from "./dateSelect";
import moment from 'moment';
import InfoIcon from '@material-ui/icons/Info';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import Snackbar from '@material-ui/core/Snackbar';

const styles = theme => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff'
  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },

});


function MySnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    ...other
  } = props;


  return (
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <InfoIcon  />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
          </IconButton>,
        ]}
        {...other}
      />
    );
  }

  MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  };

  const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

class AdvancedSelect extends React.Component {
  constructor(props) {
    super(props)
    this.getSelectedQueryDevices = this.getSelectedQueryDevices.bind(this)
    this.getQuerytypeSelection = this.getQuerytypeSelection.bind(this)
    this.handleAdvancedSelection = this.handleAdvancedSelection.bind(this)
    this.handleDateChanges = this.handleDateChanges.bind(this)

    var today = new Date(),
          // eslint-disable-next-line
          date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    var endDate = new Date(),
    // eslint-disable-next-line
          date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    endDate.setMonth(endDate.getMonth() - 1)
    this.state = {
      open: false,
      message: "",
      openSnackbar: false,
      callType: 'datapoints',
      callValue: '1',
      max: this.props.limit,
      selectedQueryDevices: [],
      firstDate: today,
      endDate: endDate,
      firstDateReady :  moment(today).format('DD-MM-YYYY'),
      endDateReady :  moment(endDate).format('DD-MM-YYYY')

    };
  }


  handleChange = name => event => {
    this.setState({
      [name]: Number(event.target.value)
    });
  };

  handleClickOpen = () => {
    this.setState({
      open: true
    });
  };

  handleClose = () => {
    this.setState({
      open: false,
      callType: 'datapoints',
      callValue: '1',
      selectedQueryDevices: []
    });
  };
  handleSnackBarClose = (event, reason) => {


     this.setState({ openSnackbar: false });
   };

  handleOk = () => {
    if (this.state.selectedQueryDevices.length === 0 || this.state.selectedQueryDevices > 5){
        this.setState(state => ({ message: 'Please select your devices, make sure you selected no more than 5.', openSnackbar: true }))
    }
    else{
      let end_date = moment(this.state.endDateReady, 'DD-MM-YYYY').toDate();
      let start_date = moment(this.state.firstDateReady, 'DD-MM-YYYY').toDate();

        if (Date.parse(end_date) > Date.parse(start_date) || isNaN(Date.parse(end_date)) === true || isNaN(Date.parse(start_date)) === true){
          this.setState(state => ({ message: 'Your date selection is invalid', openSnackbar: true }))
        }
        else{
            this.props.atChange(this.state.callType, this.state.callValue, this.state.selectedQueryDevices,  this.state.endDateReady, this.state.firstDateReady)
            // reset state for selected devices
            this.setState({
              open: false,
              selectedQueryDevices: [],
              callType: 'datapoints'
            });

          }
        }
};

  handleSlider(call_type, call_value) {
    this.setState({
      callType: call_type,
      callValue: call_value
    })
  }

  getQuerytypeSelection(selection){
    if (selection === 'analytic'){
      this.setState({
        callType: selection,
      })
    }
    else {
    this.setState({
      callType: selection,
    })
  }

  }

  getSelectedQueryDevices(devices){
    this.setState({selectedQueryDevices: devices})
  }

  handleDateChanges(date_type, value){
    if (date_type === 'firstDate'){
      this.setState({endDateReady: value, callType: 'analytic', callValue: 'by date (based on 24hr data)'})
    } else if (date_type === 'endDate'){
      this.setState({firstDateReady: value, callType: 'analytic', callValue: 'by date (based on 24hr data)'})
    }
  }
  //handle advanced selection
  handleAdvancedSelection(call_type, call_value) {
    // call_type and call_value are returned by the child components
    // we pass the results into the API caller
    //as the state doesnt update in time use setState with callback before calling API
    this.setState({
      callType: call_type,
      callValue: call_value
    })

  }
  manualInput(event) {
    let tmpValue = event.target.value;
    if (tmpValue >= 1 && tmpValue <= this.state.max) {
      this.setState({
        callType: 'datapoints',
        callValue: event.target.value
      })
    }
  }

  render() {
    const {
      classes
    } = this.props;

    return (
      <div>
        <Button onClick={this.handleClickOpen} size="small" variant="contained" color="primary" disabled={!this.props.disable}>Advanced Selection</Button>
        <Dialog
          disableBackdropClick
          disableEscapeKeyDown
          open={this.state.open}
          onClose={this.handleClose}
          fullWidth={true}
        >
          <DialogTitle>Summary selection for {this.props.dtype}<hr /></DialogTitle>
  <DialogContent>
  <TransferList devices={this.props.devices} atChange={this.getSelectedQueryDevices.bind(this)}/>
  <CheckboxesGroup classes={this.props.classes} atChange={this.getQuerytypeSelection.bind(this)}/>
  {(() => {
  switch(this.state.callType) {
    case 'datapoints':
      return      <div className={classes.root} style={{
              color: '#fff', textAlign: 'center'
            }}><DialogSelect atChange={this.handleAdvancedSelection.bind(this)} limit={1000} disable={this.props.disable}/> </div>;
    case 'day':
      return      <div className={classes.root} style={{
              color: '#fff', textAlign: 'center'
            }}><ControlledOpenSelect atChange={this.handleAdvancedSelection.bind(this)} limit={true} disable={this.props.disable}/> </div>;
    case 'analytic':
      return <div className={classes.root} style={{
              color: '#fff', textAlign: 'center'
            }}>< DatePickers firstDate={this.state.firstDate} endDate={this.state.endDate} atChange={this.handleDateChanges.bind(this)}/></div>;
    default:
      return null;
  }
})()}
  </DialogContent>
          <DialogActions>
            <Button onClick={this.handleClose} color="secondary">
              Cancel
            </Button>
              <Button onClick={this.handleOk} color="secondary">
                          Ok
              </Button>

          </DialogActions>
        </Dialog>
        <Snackbar
          message={this.state.message}
          open={this.state.openSnackbar}
          autoHideDuration={2000}
          onClose={this.handleSnackBarClose}
        ><MySnackbarContentWrapper
        variant="info"
        className={classes.margin}
        message={this.state.message}
        /></Snackbar>
      </div>
    );
  }
}

AdvancedSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(AdvancedSelect);
