import React from 'react';
import TextField from '@material-ui/core/TextField';
import moment from 'moment';
/* use a function for the exact format desired... */
function ISODateString(d, it){
    function pad(n){return n<10 ? '0'+n : n}
    return d.getUTCFullYear()+'-'
    + pad(d.getUTCMonth()+it)+'-'
    + pad(d.getUTCDate())
}

export default function DatePickers(props) {
    const classes = props;

    function handleChange1(e){
      props.atChange('firstDate', moment(e.target.value).format('DD-MM-YYYY'))
    }
    function handleChange2(e){
      props.atChange('endDate', moment(e.target.value).format('DD-MM-YYYY'))
    }
  var today = ISODateString(props.firstDate, 1)
  var endDate = ISODateString(props.endDate, 1)

  return (
    <form className={classes.container} noValidate>
      <TextField
        id="firstDate"
        label="Start date"
        type="date"
        defaultValue={endDate}
        onChange={handleChange1.bind(this)}
        className={classes.textField}
        required={true}
        InputLabelProps={{
          shrink: true,
        }}
      />
      <TextField
        id="endDate"
        label="End date"
        type="date"
        defaultValue={today}
        onChange={handleChange2.bind(this)}
        required={true}
        className={classes.textField}
        InputLabelProps={{
          shrink: true,
        }}
      />
    </form>

  );
}
