
export const getMeasurementsDefault = (deviceId, call_type, call_value) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(`/api/devices/${deviceId.id}/ndvi/measurements/${call_type}/${call_value}/`, {headers, })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          return dispatch({type: 'FETCH_MEASUREMENTS', measurements: res.data});
        } else if (res.status === 401 || res.status === 403) {
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        }
      })
  }

}

export const getMeasurementsChl = (deviceId, call_type, call_value) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }


    return fetch(`/api/devices/${deviceId.id}/chl/measurements/${call_type}/${call_value}/`, {headers, })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          return dispatch({type: 'FETCH_MEASUREMENTS_CHL', measurementsChl: res.data});
        } else if (res.status === 401 || res.status === 403) {
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        }
      })
  }

}


export const getImage = (deviceId, imageType) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;

    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    return fetch(`/api/devices/${deviceId.id}/${imageType}/measurements/image/`, {headers, })
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          return dispatch({type: 'FETCH_IMAGE', image: res.data});
        } else if (res.status === 401 || res.status === 403) {
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        }
      })
  }

}

export const getMeasurementsAll = (deviceIds, call_type, call_value, data_type) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }
    let body = JSON.stringify({deviceIds, })
    return fetch(`/api/summary/${data_type}/measurements/${call_type}/${call_value}/`, {headers, method: "POST", body})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          return dispatch({type: 'FETCH_MEASUREMENTS_ALL', measurementsAll: res.data});
        } else if (res.status === 401 || res.status === 403) {
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        }
      })
  }

}

export const getMeasurementsAnalytic = (deviceIds, data_type, startDate, endDate) => {
  return (dispatch, getState) => {
    let headers = {"Content-Type": "application/json"};
    let {token} = getState().auth;
    if (token) {
      headers["Authorization"] = `Token ${token}`;
    }

    let body = JSON.stringify({deviceIds, startDate, endDate})
    return fetch(`/api/summary/${data_type}/analytics/`, {headers, method: "POST", body})
      .then(res => {
        if (res.status < 500) {
          return res.json().then(data => {
            return {status: res.status, data};
          })
        } else {
          console.log("Server Error!");
          throw res;
        }
      })
      .then(res => {
        if (res.status === 200) {
          return dispatch({type: 'FETCH_MEASUREMENTS_ALL', measurementsAll: res.data});
        } else if (res.status === 401 || res.status === 403) {
          dispatch({type: "AUTHENTICATION_ERROR", data: res.data});
          throw res.data;
        }
      })
  }

}
export const clearDataSets = () => {
  return(dispatch, getState) => {
    return dispatch({type: 'RESET_DATASETS'})
  }
}

export const clearDataSetsChl = () => {
  return(dispatch, getState) => {
    return dispatch({type: 'RESET_DATASETS_CHL'})
  }
}

export const clearIMGDataSets = () => {
  return(dispatch, getState) => {
    return dispatch({type: 'RESET_IMG_DATASETS'})
  }
}
