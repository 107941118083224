import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import {
  measurements,
  devices
} from "../actions";
import history from '../history';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonAppBar from "./NavBar";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import SummaryStatistics from "./summaryStatistics";
import SummaryNDVIChart from "./summaryNdviChart";
import SummaryQuery from './summaryQuery'
import CheckboxesGroup from './summaryPickDType';
import DenseTable from './summaryTable';
import AdvancedSelect from './summaryAdvancedSelectionPanel';

const styles = theme => ({
  root: {
    flexGrow: 1,

  },
  fab: {
    margin: theme.spacing.unit * 2
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridList: {
    width: '100%',
    height: '20%',
  },
  icon: {
    color: 'rgba(255, 255, 255, 0.54)',
  },
  tiles: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#303030"
    }
  },
  headline: {
    textAlign: 'right', // <-- the magic
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: 40,
    marginRight: 5,

  }
});
const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      light: '#7d97ce',
      main: '#00043d',
      dark: '#5b79bb',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
    }
  },

  typography: {
    useNextVariants: true
  },
});

class Summary extends Component {
  constructor(props) {
    super(props)
    this.handleChartSelection = this.handleChartSelection.bind(this)
    this.stateHandlerDtypeSelection = this.stateHandlerDtypeSelection.bind(this)
    this.callDeviceDashboard = this.callDeviceDashboard.bind(this)
    this._handleAdvancedSubmit = this._handleAdvancedSubmit.bind(this)
    this.state = {
      callType: 'datapoints',
      callValue: 60,
      autoUpdate: false,
      counter: 60,
      selectionDevices: [],
      blockRender: false,
      dataType: 'ndvi',
      allDevices: [],
      firstDateReady : "",
      endDateReady: ""
    }

  }


  shouldComponentUpdate(nextProps, nextState) {

    // if there is a change in measurements, rerender
    if (nextProps.measurementsAll !== this.props.measurementsAll) {

      return true
    }
     else {
      // go back to initialState for redux
      this.props.clearState()
      return false
    }
  }


  componentDidMount() {

    if (this.props.location.user !== undefined && this.props.location.logout !== undefined) {

      let devices = this.props.location.devices
      let allowedDevices = this.props.location.allowedDevices
      this.setState({allDevices: devices})
      allowedDevices.map(device => this.state.selectionDevices.push(device.id))
      // call the getMeasurementsAll
      this.props.getMeasurementsAll(this.state.selectionDevices, this.state.callType, this.state.callValue, this.state.dataType);


    }
    // if there is no device passed to this function go back to home
    else {
      history.push({
        pathname: "/"
      })
    }
  }



  callDeviceDashboard(pk){

    // call the dashboard component with deviceID
    history.push({pathname: "/dashboard", deviceId: pk, user: this.props.user, logout: this.props.location.logout, devices: this.props.location.devices})
  }

  //handle chart data
  handleChartSelection(call_type, call_value) {
    // call_type and call_value are returned by the child component ControlledOpenSelect
    // we pass the results into the API caller

    //as the state doesnt update in time use setState with callback before calling API
    this.setState({
      callType: call_type,
      callValue: call_value
    }, function() {
      this._handleSubmit();
    });
  }


  _handleSubmit() {
    this.props.getMeasurementsAll(this.state.selectionDevices, this.state.callType, this.state.callValue, this.state.dataType);
  }
  _handleAdvancedSubmit(call_type, call_value, selected_devices, firstDate, endDate) {
    let deviceIds = []
    selected_devices.map(device => deviceIds.push(device.id))
    this.setState({callValue: call_value, callType: call_type})
    if (call_type === 'datapoints' || call_type === 'day'){
      this.props.getMeasurementsAll(deviceIds, call_type, call_value, this.state.dataType);
    }
    else if (call_type === 'analytic'){
      this.props.getMeasurementsAnalytic(deviceIds, this.state.dataType, firstDate, endDate)
      this.setState({firstDateReady: firstDate, endDateReady: endDate})
    }
    // update state
    this.setState({callValue: call_value, callType: call_type, selectionDevices: deviceIds})
  }

    stateHandlerDtypeSelection(dtype) {
    this.setState({dataType: dtype})
    if (this.state.callType === 'datapoints' || this.state.callType === 'day'){
      this.props.getMeasurementsAll(this.state.selectionDevices, this.state.callType, this.state.callValue, dtype);
    }
    else if (this.state.callType === 'analytic'){
      this.props.getMeasurementsAnalytic(this.state.selectionDevices, dtype, this.state.firstDateReady, this.state.endDateReady)
    }
}








  render() {
    const {
      classes
    } = this.props;


    if (this.props.location.user !== undefined && this.props.location.logout !== undefined) {
      return (<div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <Helmet bodyAttributes={{
            style: 'background-color : #303030'
          }}/>
        <ButtonAppBar user={this.props.location.user} logout={this.props.location.logout}/>

        <br/>

        <Grid container spacing={16} style={{
    margin: 0,

    width: '100%',
  }}>
          <Grid item xs={12} sm={6} >
            <Card >
              <CardContent>
                <Typography variant="h5" gutterBottom component="h2" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                  History
                </Typography>
                <hr/>
                <Typography component="div">
                <CheckboxesGroup classes={this.props.classes} atChange={this.stateHandlerDtypeSelection.bind(this)}/>
                <AdvancedSelect dtype={this.state.dataType} devices={this.state.allDevices} atChange={this._handleAdvancedSubmit.bind(this)} disable={this.props.sumAPIState}/>
                <br/>
                {/*verify if the data is ready by checking the isloaded variable*/}
                {this.props.sumAPIState ?
                  <SummaryNDVIChart callType={this.state.callType} dataType={this.state.dataType} dataSetSum={this.props.measurementsAll} readyness={this.props.sumAPIState} classes={this.props.classes}/>

                  : <SummaryNDVIChart callType={this.state.callType} dataSetSum={this.props.measurementsAll} dataType={this.state.dataType} readyness={this.props.sumAPIState} classes={this.props.classes}/> }

                </Typography>

              </CardContent>

            </Card>

            <br/>
            <Card >
            <SummaryQuery dataType={this.state.dataType} callType={this.state.callType}
            sumAPIState={this.props.sumAPIState} callValue={this.state.callValue}
             selectionDevices={this.state.selectionDevices} firstDate={this.state.firstDateReady}
             endDate={this.state.endDateReady}/>

            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Card className={classes.card} >
          <CardContent>

          <Typography  variant="h5" gutterBottom component="h2" style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    Statistics


          </Typography>
            <hr/>
            {/*verify if the data is ready by checking the isloaded variable*/}
            {this.props.sumAPIState ?
              <SummaryStatistics dataType={this.state.dataType} dataSetSum={this.props.measurementsAll} readyness={this.props.sumAPIState} classes={this.props.classes}/>
              : <SummaryStatistics dataType={this.state.dataType} dataSetSum={this.props.measurementsAll} readyness={this.props.sumAPIState} classes={this.props.classes}/> }
              <br/>
              {this.props.sumAPIState ?
              <DenseTable  atChange={this.callDeviceDashboard.bind(this)} dataType={this.state.dataType} dataSetSum={this.props.measurementsAll} readyness={this.props.sumAPIState} classes={this.props.classes}/>
              : <DenseTable  atChange={this.callDeviceDashboard.bind(this)} dataType={this.state.dataType} dataSetSum={this.props.measurementsAll} readyness={this.props.sumAPIState} classes={this.props.classes}/>
              }
          </CardContent>


          </Card>
          </Grid>
        </Grid>

      </MuiThemeProvider>
    </div>)
    } else {
      return (<div>Going back to manage devices</div>)
    }
  }

}


const mapStateToProps = state => {


  return {
    user: state.auth.user,
    measurementsAll: state.measurements.data,
    sumAPIState: state.measurements.isLoaded
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDevices: () => {
      dispatch(devices.fetchDevices());
    },
    getMeasurementsAll: (deviceIds, call_type, call_value, data_type) => {
      return dispatch(measurements.getMeasurementsAll(deviceIds, call_type, call_value, data_type));
    },
    getMeasurementsAnalytic: (deviceIds, data_type, start_date, end_date) => {
      return dispatch(measurements.getMeasurementsAnalytic(deviceIds, data_type, start_date, end_date));
    },
    clearState: () => {
      return dispatch(measurements.clearDataSets());
    },

  };

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Summary));
