import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import DeleteIcon from '@material-ui/icons/Delete';
import Button from '@material-ui/core/Button';
import SaveIcon from '@material-ui/icons/Save';
import DashboardIcon from '@material-ui/icons/Dashboard';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import classNames from 'classnames';

const styles = theme => ({
  fab: {
    margin: theme.spacing.unit
  },
  extendedIcon: {
    marginRight: theme.spacing.unit
  }
});

function FloatingActionButtons(props) {
  const {classes} = props;
  return (<div>
    {
      props.edit
        ? <Fab onClick={props.editFunc} color="primary" size="small" aria-label="Add" className={classes.fab}>
            <Icon>edit_icon</Icon>
          </Fab>
        : null
    }
    {
      props.reset
        ? <Fab onClick={props.resetFunc} color="primary" size="small" aria-label="reset" className={classes.fab}>
            <Icon>loop</Icon>
          </Fab>
        : null
    }
    {
      props.delete
        ? <Fab onClick={props.deleteFunc} aria-label="Delete" size="small" className={classes.fab}>
            <DeleteIcon/>
          </Fab>
        : null
    }
    {
      props.save
        ? <Button variant="contained" type={props.type} size="small" className={classes.fab}>
            <SaveIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
            Save Device
          </Button>
        : null
    }
    {
      props.manage
        ? <Fab href="/" color="default" size="small" aria-label="manage" className={classes.fab}>
            <Icon>settings</Icon>
          </Fab>
        : null
    }
    {
      props.info
        ? <Button variant="contained" onClick={props.infoFunc} size="small" className={classes.fab}>
            <DashboardIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
            UI
          </Button>

        : null
    }
    {
      props.summary
        ? <p  style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center'
          }}>
        <Button variant="contained" color="primary" onClick={props.summaryFunc} size="small" className={classes.fab}>
            <ViewModuleIcon className={classNames(classes.leftIcon, classes.iconSmall)}/>
            Summary
          </Button>
          </p>

        : null
    }
  </div>);
}

FloatingActionButtons.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(FloatingActionButtons);
