import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import {
  measurements,
  devices
} from "../actions";
import {
  withStyles
} from '@material-ui/core/styles';
import Chip from '@material-ui/core/Chip';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Fab from '@material-ui/core/Fab';
import Icon from '@material-ui/core/Icon';
import Switch from '@material-ui/core/Switch';
import Snackbar from '@material-ui/core/Snackbar';
import InfoIcon from '@material-ui/icons/Info';
import PropTypes from 'prop-types';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';
import SnackbarContent from '@material-ui/core/SnackbarContent';
const styles = theme => ({
  root: {
    flexGrow: 1,

  },
  info: {
    backgroundColor: theme.palette.primary.dark,
  },
  icon: {
    fontSize: 20,
  },
  iconVariant: {
    opacity: 0.9,
    marginRight: theme.spacing.unit,
  },
  message: {
    display: 'flex',
    alignItems: 'center',
    color: '#fff'
  },
  fab: {
    margin: theme.spacing.unit * 2
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  gridList: {
    width: '600%',
    height: '20%',
  },

  tiles: {
    "&:hover": {
      //you want this to be the same as the backgroundColor above
      backgroundColor: "#303030"
    }
  },
  headline: {
    textAlign: 'right', // <-- the magic
    fontWeight: 'bold',
    fontSize: 24,
    marginTop: 40,
    marginRight: 5,

  }
});

function MySnackbarContent(props) {
  const {
    classes,
    className,
    message,
    onClose,
    variant,
    ...other
  } = props;


  return (
      <SnackbarContent
        className={classNames(classes[variant], className)}
        aria-describedby="client-snackbar"
        message={
          <span id="client-snackbar" className={classes.message}>
            <InfoIcon  />
            {message}
          </span>
        }
        action={[
          <IconButton
            key="close"
            aria-label="Close"
            color="inherit"
            className={classes.close}
            onClick={onClose}
          >
          </IconButton>,
        ]}
        {...other}
      />
    );
  }

  MySnackbarContent.propTypes = {
    classes: PropTypes.object.isRequired,
    className: PropTypes.string,
    message: PropTypes.node,
    onClose: PropTypes.func,
    variant: PropTypes.oneOf(['success', 'warning', 'error', 'info']).isRequired,
  };

  const MySnackbarContentWrapper = withStyles(styles)(MySnackbarContent);

// seperate class for the summary query component
// here we want to rerender each time any updates, which is not the case for data plotting components
class SummaryQuery extends Component {
  constructor(props) {
    super(props);
    this.newQuery = this.newQuery.bind(this)
    this.handleChangeAuto = this.handleChangeAuto.bind(this)
    this.state = {...props, autoUpdate: false,counter: 60, text: "",
    message: "",
    open: false}
  }
  shouldComponentUpdate(nextProps, nextState) {

    if (this.state.callType !== nextProps.callType){
      this.setState({'callType':nextProps.callType})
    }
    if (this.state.callValue !== nextProps.callValue){
      this.setState({'callValue': nextProps.callValue})
    }
    if (this.state.APIState !== nextProps.APIState){
      this.setState({'APIState': nextProps.APIState})
    }
    return true
  }

  // refresh button handler
  newQuery = () => {
    // go back to initialState for redux
    this.props.clearState()
    this.props.clearStateChl()
    this.props.clearIMG()
    this.setState(state => ({ message: 'Looking for new data...', open: true }))
    this.props.getMeasurementsDefault(this.props.selectedDevice, this.state.callType, this.state.callValue);
    this.props.getMeasurementsChl(this.props.selectedDevice, this.state.callType, this.state.callValue);
    this.props.getImage(this.props.selectedDevice, this.props.imageType);
  }

  // countdown function
  countDown(switch_val, count_val) {

    function sleep(time) {

      return new Promise((resolve) => setTimeout(resolve, time));
    }
    // if the switch is on and the counter is above zero
    if (switch_val === true && count_val >= 0) {

      // if count_val reaches 0 invoke react redux
      if (count_val === 0) {
        // call API
        this.props.clearState()
        this.props.clearStateChl()
        this.props.clearIMG()
        this.props.getMeasurementsDefault(this.props.selectedDevice, this.state.callType, this.state.callValue);
        this.props.getMeasurementsChl(this.props.selectedDevice, this.state.callType, this.state.callValue);
        this.props.getImage(this.props.selectedDevice, this.props.imageType);
        count_val = 60

      }
      // countdown
      count_val = count_val - 1
    this.setState({'counter': count_val})
      sleep(500).then((response) => {
        // as long as the switch is on true
        if (this.state.autoUpdate === true) {
          // call the countdown function internally with the sleep promise
          this.countDown(true, count_val)
        }
      })
    } else {
      // call the countdown function internally
      this.setState({'counter': 60})
    }
  }
  handleClose = (event, reason) => {


     this.setState({ open: false });
   };

  // switch handler
  handleChangeAuto = name => event => {
      this.setState({ [name]: event.target.checked });

      this.countDown(event.target.checked, this.state.counter);
    };

render(){
   const { classes } = this.props;
  return (
     <CardContent>
          <Typography variant="h5" gutterBottom component="h2" style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            API Query details

          </Typography>
          <hr/>

          <span>&nbsp;&nbsp;</span>
          <FormControlLabel labelPlacement="end"
          control={
          <Fab onClick={this.newQuery} disabled={!this.state.APIState} color="primary" size="small" aria-label="reset" >
            <Icon fontSize="small">loop</Icon>
          </Fab>
        }
      label="&nbsp; Refresh query"
      />
          <FormControlLabel labelPlacement="end"
           control={
            <Switch
              disabled={!this.state.APIState}
              checked={this.state.autoUpdate}
              onChange={this.handleChangeAuto('autoUpdate')}
              value="autoUpdate"
              color="primary"
            />
            }
          label="Auto update"
          />
          {
            this.state.autoUpdate ? <Chip color="primary" label={"Updating in " + this.state.counter}/>
            : null
          }
          <Snackbar
      message={this.state.message}
      open={this.state.open}
      autoHideDuration={2000}
      onClose={this.handleClose}
    ><MySnackbarContentWrapper
    variant="info"
    className={classes.margin}
    message={this.state.message}
  /></Snackbar>
          <br/><br/>

            {
              this.state.callType === 'day'
                ? <Chip color="default" label={'Number of days selected: ' + this.state.callValue}/>
                : <Chip color="default" label={'Number of datapoints selected: ' + this.state.callValue}/>
            }
            <br/><br/>


        </CardContent>
              )
}
}

const mapStateToProps = state => {


return {user: state.auth.user,
  measurements: state.measurements.data,
  APIState: state.measurements.isLoaded,
  measurementsChl: state.measurementsChl,
  APIchlState: state.measurementsChl.isLoaded,
}
}

const mapDispatchToProps = dispatch => {
return {
  fetchDevices: () => {
    dispatch(devices.fetchDevices());
  },
  getMeasurementsDefault: (deviceId, call_type, call_value) => {
    return dispatch(measurements.getMeasurementsDefault(deviceId, call_type, call_value));
  },
  getMeasurementsChl: (deviceId, call_type, call_value) => {
    return dispatch(measurements.getMeasurementsChl(deviceId, call_type, call_value));
  },
  clearState: () => {
    return dispatch(measurements.clearDataSets());
  },
  clearStateChl: () => {
    return dispatch(measurements.clearDataSetsChl());
  },
  clearIMG: () => {
    return dispatch(measurements.clearIMGDataSets());
  },
  getImage: (deviceId, imageType) => {
    return dispatch(measurements.getImage(deviceId, imageType));
  },

};

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(SummaryQuery));
