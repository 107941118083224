import { combineReducers } from 'redux';
import devices from "./devices";
import auth from "./auth";
import measurements from "./measurements"
import measurementsChl from "./measurementsChl"
import image from "./image"
const mainApp = combineReducers({
  devices, auth,measurements,image, measurementsChl
})

const rootReducer = (state, action) => {
switch (action.type) {
case 'AUTHENTICATION_ERROR':
case 'LOGIN_FAILED':
case 'REGISTRATION_FAILED':
case 'LOGOUT_SUCCESSFUL':
state = {...state, 'devices': [], 'measurements': [], 'image': [], 'measurementsChl': []};
break;

default:
break;
}

return mainApp(state, action);
};

export default rootReducer;
