import React from 'react';
import Grid from '@material-ui/core/Grid';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

/*
function not(a, b) {
  return a.filter(value => b.indexOf(value) === -1);
}

function intersection(a, b) {
  return a.filter(value => b.indexOf(value) !== -1);
}
*/

//THIS function is heavily modified for simplicity,
//if DnD is deemed necessary uncomment all and adapt
export default function TransferList(props) {
  const classes = props;
  const [checked, setChecked] = React.useState([]);
  var deviceIds = []
  props.devices.map((device, id) => (
    deviceIds.push(device.id)
 ))
  var [left] = React.useState(props.devices);
  //var [right] = React.useState([]);
  var [leftChecked, setLeftCheck] = React.useState([]);
  //var rightChecked = intersection(checked, right);
  // if the user clicked on the checkbox on the left
  const handleToggle = value => () => {
    const currentIndex = checked.indexOf(value);
    const newChecked = [...checked];


    if (currentIndex === -1) {
      newChecked.push(value);
      leftChecked.push(value)
    } else {
      newChecked.splice(currentIndex, 1);
      leftChecked.splice(currentIndex, 1)
    }
    setChecked(newChecked);
    setLeftCheck(leftChecked)
    let toAdd = []
    for (const ids of leftChecked){
      // we now know what is checked on the left
      let foundDeviceDetails = props.devices.find(device => device.id === ids )
      toAdd.push(foundDeviceDetails)
      }
      returnSelToPanel(toAdd)
    };

/*
  const handleAllRight = () => {

    setRight(left)
    setLeft([]);
  };
*/
  const returnSelToPanel = (devices) => {
      props.atChange(devices)
  }
/*
  const handleCheckedRight = () => {
    let toAdd = []
    for (const ids of leftChecked){
      // we now what is checked on the left, we in turn find it and set the Right var to it
      let foundDeviceDetails = props.devices.find(device => device.id === ids )
      toAdd.push(foundDeviceDetails)
      }
    setRight(toAdd)

    // combine all devices with the ones identified to be checked
    const combined = toAdd.concat(props.devices)
    // exclude all duplicated in the following function (quick fix)
    const toKeep = combined.filter(function(a){
    return combined.indexOf(a) === combined.lastIndexOf(a)
});
    setLeft(toKeep)

  };
  */
/*
  const handleCheckedLeft = () => {
    setLeft(left.concat(rightChecked));

    setRight(not(right, rightChecked));
    setChecked(not(checked, rightChecked));

  };
  */
/*
  const handleAllLeft = () => {
    setLeft(left.concat(right));
    setRight([]);

  };
*/




  const customList = items => (
  <List dense component="div" role="list">
  {left.map((value, id) => {
    const labelId = `transfer-list-item-${value.id}-label`;
    return (
   <ListItem key={value.id} role="listitem" button onClick={handleToggle(value.id)}>
     <ListItemIcon>
       <Checkbox
         checked={checked.indexOf(value.id) !== -1}
         tabIndex={-1}
         disableRipple
         style= {
       {
         color: '#5b79bb',
       }}
         inputProps={{ 'aria-labelledby': labelId }}
       />
     </ListItemIcon>
     <ListItemText id={labelId} primary={`${value.text}`} />
   </ListItem>
 );
})}
</List>

)
/*
const customList2 = items => (
      <List dense component="div" role="list">
        {right.map((value, id)=> {
          const labelId = `transfer-list-item-${value.id}-label`;

          return (
            <ListItem key={value.id} role="listitem" button onClick={handleToggle(value.id)}>

              <ListItemText id={labelId} primary={`${value.text}`} />
            </ListItem>
          );
        })}
        <ListItem />
      </List>
  );
*/

    return (
    <div>
    <p style={{
        color: '#fff', textAlign: 'center'
      }}>Select your devices (max. 5) : </p>
    <Grid container spacing={8} justify="center" alignItems="center" className={classes.root}>
      <Grid item>{customList(left)}</Grid>
      {/*
      <Grid item>
        <Grid container direction="column" alignItems="center">
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllRight}
            disabled={left.length === 0}
            aria-label="move all right"
          >
            ≫
          </Button>
          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleCheckedRight}
            disabled={left.length === 0}
            aria-label="move selected right"
          >
            &gt;
          </Button>

          <Button
            variant="outlined"
            size="small"
            className={classes.button}
            onClick={handleAllLeft}
            disabled={right.length === 0}
            aria-label="move all left"
          >
            ≪
          </Button>
        </Grid>
      </Grid>
      <Grid item>{customList2(right)}</Grid>
      */}
    </Grid>
    </div>
  );
}
