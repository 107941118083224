import React from 'react';
import GridList from '@material-ui/core/GridList';
import GridListTile from '@material-ui/core/GridListTile';
import GridListTileBar from '@material-ui/core/GridListTileBar';
import MinimizeIcon from '@material-ui/icons/Minimize';
import MaximizeIcon from '@material-ui/icons/Maximize';
import CompareArrowsIcon from '@material-ui/icons/CompareArrows';
import CircularProgress from '@material-ui/core/CircularProgress';

function SummaryStatistics(props) {
  const {
    classes
  } = props;

  // verify if the graph is ready to render (in case data is available)
  if (props.readyness !== false && props.dataSetSum !== undefined) {
    let avgQuery = 'summary_avg_'+ props.dataType
    // however if the data is null, because the device is configured but no data avaiable yet
    if (props.dataSetSum[0].summary_stats[avgQuery] === null) {
      return (<div style={{display: 'flex', justifyContent:'center', alignItems:'center', color:'#fff'}}>Requested data is not available.</div>)
    } else {

      var minQuery = props.dataSetSum[0].summary_stats['summary_min_' + props.dataType]
      var minTitle = 'Min. ' + props.dataType.toUpperCase()
      var minQueryName = props.dataSetSum[0].summary_stats['summary_min_' + props.dataType + '_name']
      var minQueryTimeStamp = props.dataSetSum[0].summary_stats['summary_min_' + props.dataType + '_timestamp']
      var maxQuery = props.dataSetSum[0].summary_stats['summary_max_' + props.dataType]
      var maxTitle = 'Max. ' + props.dataType.toUpperCase()
      var maxQueryName = props.dataSetSum[0].summary_stats['summary_max_' + props.dataType + '_name']
      var maxQueryTimeStamp = props.dataSetSum[0].summary_stats['summary_max_' + props.dataType + '_timestamp']
      // eslint-disable-next-line
      var avgQuery = props.dataSetSum[0].summary_stats['summary_avg_' + props.dataType]
      var avgTitle = 'Avg. ' + props.dataType.toUpperCase()

      return (
        <GridList cellHeight={60} className={classes.gridList}>

    {minQuery < 0.3 ?
    <GridListTile>
    <GridListTileBar className={classes.tiles}
    title={minTitle}
    subtitle={<div>{minQueryName}<br/>  {minQueryTimeStamp} </div>}
    actionIcon={
    <div>
    <p className={classes.headline} style={{color: '#EA4541'}}>{minQuery.toFixed(2)}
      <MinimizeIcon className={classes.icon} /></p>
    </div>
    }
    />

    </GridListTile>
    :     <GridListTile>
    <GridListTileBar className={classes.tiles}
    title={minTitle}
    subtitle={<div>{minQueryName}<br/>  {minQueryTimeStamp} </div>}
    actionIcon={
    <div>
    <p className={classes.headline} style={{color: '#EA963B'}}>{minQuery.toFixed(2)}
      <MinimizeIcon className={classes.icon} /></p>
    </div>
        }
        />
        </GridListTile>}
        <GridListTile>
          <GridListTileBar className={classes.tiles}
          title={maxTitle}
          subtitle={<div>{maxQueryName}<br/> {maxQueryTimeStamp} </div>}
          actionIcon={
          <div>
          <p className={classes.headline} style={{color: '#52AB56'}}>{maxQuery.toFixed(2)}
            <MaximizeIcon className={classes.icon} /></p>
          </div>

          }
          />
          </GridListTile>
          <GridListTile>
          <GridListTileBar className={classes.tiles}
          title={avgTitle}
          subtitle={<div>From all devices <br/> </div>}
          actionIcon={ <div>
          <p className={classes.headline} style={{color: '#7d97ce'}}>{avgQuery.toFixed(2)}
          <CompareArrowsIcon className={classes.icon} /></p>
          </div>
          }
          />
      </GridListTile>

    </GridList>


      );
    }
  } else {
    return (<div style={{display: 'flex',  justifyContent:'center', alignItems:'center',  color:'#fff'}}>
   <CircularProgress className={classes.progress} color="secondary" /><span>&nbsp;&nbsp;</span> Loading...</div>)
  }


}
export default SummaryStatistics;
