import React from 'react';
import PropTypes from 'prop-types';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles from '@material-ui/core/styles/withStyles';
//import {Link} from "react-router-dom";

const styles = theme => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto'
    }
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
    color: 'white'
  },
  submit: {
    marginTop: theme.spacing.unit * 3
  }
});

function SignIn(props) {
  const {classes} = props;

  return (<div>
    {
      props.login
        ? <main className={classes.main}>
            <CssBaseline/>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Sign in
              </Typography>
              <form className={classes.form} onSubmit={props.onSubmit}>
                {
                  props.errors.length > 0 && (<ul>
                    {props.errors.map(error => (<li key={error.field}>{error.message}</li>))}
                  </ul>)
                }
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="username">User name</InputLabel>
                  <Input onChange={e => props.atChangeUser(e.target.value)} id="username" name="username" autoComplete="username" autoFocus/>
                </FormControl>
                <FormControl margin="normal" required fullWidth>
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input onChange={e => props.atChangePwd(e.target.value)} name="password" type="password" id="password" autoComplete="current-password"/>
                </FormControl>
                <FormControlLabel control={<Checkbox value = "remember" color = "primary" />} label="Remember me"/>
                <Button type="submit" fullWidth variant="contained" color="primary" className={classes.submit}>
                  Sign in
                </Button>
                {/*
                Don't have an account?
                <Link to="/register">Register</Link>
                */
                }
                </form>
            </Paper>
          </main>
        : null
    } {
      props.register
        ? <main className={classes.main}>
            <CssBaseline/>
            <Paper className={classes.paper}>
              <Avatar className={classes.avatar}>
                <LockOutlinedIcon/>
              </Avatar>
              <Typography component="h1" variant="h5">
                Register
              </Typography>
              <form className={classes.form} onSubmit={props.onSubmit}>
                {
                  props.errors.length > 0 && (<ul>
                    {props.errors.map(error => (<li key={error.field}>{error.message}</li>))}
                  </ul>)
                }
                <FormControl margin="normal" required="required" fullWidth="fullWidth">
                  <InputLabel htmlFor="username">User name</InputLabel>
                  <Input onChange={e => props.atChangeUser(e.target.value)} id="username" name="username" autoComplete="username" autoFocus/>
                </FormControl>
                <FormControl margin="normal" required="required" fullWidth="fullWidth">
                  <InputLabel htmlFor="password">Password</InputLabel>
                  <Input onChange={e => props.atChangePwd(e.target.value)} name="password" type="password" id="password" autoComplete="current-password"/>
                </FormControl>
                <FormControlLabel control={<Checkbox value = "remember" color = "primary" />} label="Remember me"/>
                <Button type="submit" fullWidth="fullWidth" variant="contained" color="primary" className={classes.submit}>
                  Register
                </Button>
              </form>
            </Paper>
          </main>

        : null
    }</div>);
}

SignIn.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(SignIn);
