import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
//import InfoIcon from '@material-ui/icons/Info';
import HelpIcon from '@material-ui/icons/Help';
import CancelIcon from '@material-ui/icons/Cancel';
import PieChartIcon from '@material-ui/icons/PieChart';
import history from '../history';
const styles = {
  list: {
    width: 250
  },
  fullList: {
    width: 'auto'
  }
};

class CustomDrawer extends React.Component {

  constructor(props) {
    super(props)
    this.cancelView = this.cancelView.bind(this)
    this.showDashboard = this.showDashboard.bind(this)
  }
  state = {
    top: false,
    left: false,
    bottom: false,
    right: false,
    customOpen: false
  };

  cancelView() {
    this.props.closeFunc(false);
  }

  showDashboard() {
    // call the dashboard component with deviceID
    history.push({pathname: "/dashboard", deviceId: this.props.deviceId, user: this.props.user, logout: this.props.logout})

  }
  showAbout(){
    history.push({pathname: "/about"})
  }

  toggleDrawer = (side, open) => () => {
    this.setState({[side]: open});
  };

  render() {

    const {classes} = this.props;

    const sideList = (<div className={classes.list}>
      <List>
        <ListItem button>
          <ListItemIcon>
            <PieChartIcon/>
          </ListItemIcon>
          <ListItemText primary="Dashboard" onClick={this.showDashboard}/>
        </ListItem>

        <ListItem button>
          <ListItemIcon>
            <HelpIcon/>
          </ListItemIcon>
          <ListItemText primary="About" onClick={this.showAbout} />
        </ListItem>


        <ListItem button>
          <ListItemIcon>
            <CancelIcon/>
          </ListItemIcon>
          <ListItemText onClick={this.cancelView} primary="Cancel"/>
        </ListItem>
      </List>
      <Divider/>

    </div>);

    return (<div>

      <Drawer open={this.props.open} onClose={this.toggleDrawer('left', false)}>
        <div tabIndex={0} role="button" onClick={this.toggleDrawer('left', false)} onKeyDown={this.toggleDrawer('left', false)}>
          {sideList}
        </div>
      </Drawer>

    </div>);
  }
}

CustomDrawer.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(CustomDrawer);
