const initialState = {data : [], isLoaded: false};


export default function image(state=initialState, action) {

  switch (action.type) {

    default:
      return state;

  case 'FETCH_IMAGE':

    return {data: [...action.image], isLoaded:true};

    case 'RESET_IMG_DATASETS':
      return initialState;

  }
}
