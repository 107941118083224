import React, {Component} from "react";
import { MuiThemeProvider, createMuiTheme, withStyles } from '@material-ui/core/styles';
import {connect} from "react-redux";
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import mainLogo from '../pics/g12.png';
import ndviPic from '../pics/NDVI_example.jpg'
import ndviHistory from '../pics/ndvi_history.png'
import apiQuery from '../pics/api_query.png'
import Helmet from 'react-helmet';
import ButtonAppBar from "./NavBar";
import {auth} from "../actions";
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Icon from '@material-ui/core/Icon';
import DashboardIcon from '@material-ui/icons/Dashboard';

const styles = theme => ({
  root: {
   flexGrow: 1,

 },
 paper: {
   display: 'flex',
   flexDirection: 'column',
   alignItems: 'center',
   padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
 },
  container: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  card: {
    minWidth: 275,
    minHeight: '20vw',
  },

  "@global": {
  		body: {
  			backgroundImage: "url('/static/pics/agriculture-country-countryside-1209290_opt.jpg')",
  			backgroundRepeat: "no-repeat",
  			backgroundPosition: "center center",
  			backgroundSize: "cover",
  			backgroundAttachment: "fixed",
  			height: "100%"
  		},
  		html: {
  			height: "100%"
  		},
  		"#componentWithId": {
  			height: "100%"
  		}
  	}
});

const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
     light: '#7d97ce',
     main: '#00043d',
     dark: '#5b79bb',
     contrastText: '#fff',
   },
   secondary:{
     light: '#fff',
     main: '#fff',
     dark: '#fff',
     contrastText: '#fff',
   }
  },

  typography: { useNextVariants: true },
});

class About extends Component {

    render() {
      const { classes } = this.props;
      return (
      <div className={classes.root}>
      <MuiThemeProvider theme={theme}>

      {this.props.user ? <ButtonAppBar user={this.props.user} logout={this.props.logout}/> : <ButtonAppBar logout={this.props.logout}/> }
      <Helmet bodyAttributes={{
          style: 'background-color : #303030'
        }}/>
          <br/>
        <Grid container spacing={16} style={{
    margin: 0,

    width: '100%',
  }}>
        <Grid item xs={12} sm={6} >
      <Card >
        <CardContent>
          <Typography component="h1" variant="h5" style={{
              textAlign: 'center'
            }}>
            <img style={{
                height: 40,
                width: 40
              }} src={mainLogo} alt="#"/><br/>
            Normalized Difference Vegetation Index
            <hr/>
          </Typography>
          <Typography component="h6" variant="h6" style={{
              color: '#7d97ce'
            }}>
          Vegetation reflectance
          </Typography>
          <p style={{
              color: '#fff'
            }}>
          Our stress monitoring technology is based on the observation that
          different objects reflect and absorb light in different ways.
          Stressed and healthy plants have different photosynthetic activity
           and interact with light in very specific ways.
          By looking beyond the visible spectrum, the camera can measure light
          reflectance variations and identify specific fingerprints of plant
          stress before they are visible to the human eye.
          </p>
          <Typography component="h6" variant="h6" style={{
              color: '#7d97ce'
            }}>
          Vegetation index
          </Typography>
          <p style={{
              color: '#fff'
            }}>
            By measuring the light reflectance it is possible
            to calculate NDVI values (Normalized Difference Vegetation Index).
            NDVI provides clear and easy to interpret information on plant vigour.
             Special camera's can capture the related color bands and after
             several processing steps NDVI values per pixel can be calculated.
            </p>
             <br /><br />
             <Paper className={classes.paper}>
             <img style={{
                 maxHeight: 200,
                 maxWidth: 240,
                 justifyContent: 'center',
                alignItems: 'center',
               }} src={ndviPic} alt="#"/><br/>
               </Paper>
             <br />
             <p style={{
                 color: '#fff'
               }}>
              Each pixel is given a value between -1 (red) and 1 (green) which is
             directly linked to plant activity. Stressed plants have a lower NDVI
              value and are represented in yellow/orange shades.
            With NDVI, it is possible to quickly identity in-field variability
            at a given moment. It can also be used to compare images over time
            to identify significant changes in plant health status.
            With these information, you can provide an early, local and adapted
            response.

          </p>
          </CardContent>
          </Card>



          </Grid>
          <Grid item xs={12} sm={6} >
          <Card >
            <CardContent>
              <Typography component="h1" variant="h5" style={{
                  textAlign: 'center'
                }}>
                <img style={{
                    height: 40,
                    width: 40
                  }} src={mainLogo} alt="#"/><br/>
                Using the software
                <hr/>
              </Typography>

                <Typography component="h6" variant="h6" style={{
                    color: '#7d97ce'
                  }}>
                Manage devices
                </Typography>
                <p style={{
                    color: '#fff'
                  }}>
                When logged in you are presented with the manage devices page.
                You can also click on the   <Icon>settings</Icon> in the
                navigation bar to access this page.
                The management page shows all devices connected to your user account.
                Each device has its own user interface (UI).
                You can access the "device dashboards" by clicking on the
                 { " " }<DashboardIcon />{ " " }
                  sign.
                </p>

                  <Typography component="h6" variant="h6" style={{
                      color: '#7d97ce'
                    }}>
                  Device Dashboard
                  </Typography>
                  <p style={{
                      color: '#fff'
                    }}>
                  The device dashboard shows three panels, 1) the NDVI history for up to 30 days,
                  2) the last processed image and 3) the API query details.
                  <br /><br />
                  The NDVI history shows the mean NDVI
                  calculated over the image taken. Use the dropdown menu
                  to select the period you wish to analyse. Additionally you
                  can click on advanced selection to select the number of datapoints.
                  </p>
                  <br /><br />
                  <Paper className={classes.paper}>
                  <img style={{
                      maxHeight: 200,
                      maxWidth: 240,
                      justifyContent: 'center',
                     alignItems: 'center',
                   }} src={ndviHistory} alt="#"/><br/>
                    </Paper>
                  <br />
                  <br /><br />
                  <p style={{
                      color: '#fff'
                    }}>
                  The last processed image shows the latest NDVI image taken by
                  the camera.
                  <br /><br />
                  Finally the API query details panel allows for analyzing your
                  query made to the database.
                  In addition there is functionality to refresh or auto-update for retrieving new data.
                  <br /><br /></p>
                  <Paper className={classes.paper}>
                  <img style={{
                      maxHeight: 200,
                      maxWidth: 240,
                      justifyContent: 'center',
                     alignItems: 'center',
                   }} src={apiQuery} alt="#"/><br/>
                    </Paper>

              </CardContent>
              </Card>
          </Grid>
        </Grid>
      </MuiThemeProvider>
      </div>
    )
    }

}
/* used to "map" the application state to the "props" of the component */
const mapStateToProps = state => {

  return {user: state.auth.user}
}

/* "mapping" action dispatcher functions to component "props". */
const mapDispatchToProps = dispatch => {
  return {
    logout: () => dispatch(auth.logout())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(About));
