import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';


export default function CheckboxesGroup(props) {
  const {
    classes
  } = props;
  const [state, setState] = React.useState({
    ndvi: true,
    chl: false,
  });

  const handleChange = (name, disableName) => event => {
      setState({ ...state, [name]: event.target.checked, [disableName]: !event.target.checked });
      props.atChange(name)

    //setState({ ...state, [name]: event.target.checked });
    //console.log(event.target.checked);
  };

  const { ndvi, chl } = state;

  return (
    <div className={classes.root}>
      <FormControl component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Radio checked={ndvi} disabled={ndvi} onChange={handleChange('ndvi', 'chl')} value="ndvi"
            style= {
          {
            color: {ndvi} ? '#5b79bb' : '#7d97ce',
          }}/>}
            label="NDVI"
          />
        </FormGroup>
      </FormControl>
      <FormControl required component="fieldset" className={classes.formControl}>
        <FormGroup>
          <FormControlLabel
            control={<Radio checked={chl} disabled={chl} onChange={handleChange('chl', 'ndvi')} value="chl"
            style= {
          {
            color: {chl} ? '#97ce7d' : '#7d97ce',

          }}/>}
            label="Chlorophyll"
          />

        </FormGroup>
      </FormControl>
    </div>
  );
}
