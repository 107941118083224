import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

const styles = theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,
  },
});

class ControlledOpenSelect extends React.Component {
  state = {
    day: '',
    open: false,
  };

  handleChange = event => {
    // call parent component with the results
    this.props.atChange(event.target.name, event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  render() {
    const {
      classes
    } = this.props;

    return (
      <form autoComplete="off">

        <FormControl className={classes.formControl} disabled={!this.props.disable}>
          <InputLabel htmlFor="controlled-open-select">timespan</InputLabel>
          <Select
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.day}
            onChange={this.handleChange}
            inputProps={{
              name: 'day',
              id: 'controlled-open-select',
            }}
          >

            <MenuItem value={1}>Past day</MenuItem>
            <MenuItem value={3}>Past three days</MenuItem>
          <MenuItem value={7} disabled={this.props.limit}>Past week</MenuItem>
          <MenuItem value={30} disabled={this.props.limit}>Past month</MenuItem>


          </Select>
        </FormControl>
      </form>
    );
  }
}

ControlledOpenSelect.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ControlledOpenSelect);
