const initialState = [];


export default function devices(state=initialState, action) {
    let deviceList = state.slice();
  switch (action.type) {

  case 'ADD_DEVICE':
     return [...state, action.device];

  case 'UPDATE_DEVICE':
  let deviceToUpdate = deviceList[action.index]
  deviceToUpdate.text = action.device.text;
  deviceList.splice(action.index, 1, deviceToUpdate);
  return deviceList;

  case 'DELETE_DEVICE':
    deviceList.splice(action.index, 1);
    return deviceList;

   case 'FETCH_DEVICES':
    return [...state, ...action.devices];

    default:
      return state;



  }
}
