import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles
} from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';
import Chip from '@material-ui/core/Chip';
//             <img src={`data:image/jpeg;base64,${this.props.image}`} />

const styles = theme => ({
  root: {
    flexGrow: 1,

  },
  fab: {
    margin: theme.spacing.unit
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  card: {
    minWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  infoSelect: {
    display: 'flex',
    flexDirection:'row',
  },

});

function ImageView(props) {

  const {
    classes
  } = props;

  // the property is received and the dataset is there
  if (props.readyness !== false && props.imageData.data !== undefined && props.imageData.data[0].image_file !== null) {
    return (

      <div>
      <Chip color="default" size="small" label={props.imageData.data[0].image_file.split('/')[4]}/>

{props.children}
      <img width={'100%'} style={{color:'#fff'}} src={`data:image/png;base64,${props.imageData.data[0]['base64_image']}`} alt="No data to show" />

</div>
    );
  }

  // the property is received and the API has responded with an empty dataset
  else if (props.readyness !== false  && props.imageData.data !== undefined && props.imageData.data[0].image_file === null) {
    return (
      <div>

<p style={{color:'#fff'}}>
    No image available. Please verify that your device is running.</p></div>

    );
  } else {
    return (<div style={{display: 'flex',  justifyContent:'center', alignItems:'center',  color:'#fff'}}>
   <CircularProgress className={classes.progress} color="secondary" /> <span>&nbsp;&nbsp;</span> Loading...</div>)
  }
}

ImageView.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(ImageView);
