import React from 'react';
import PropTypes from 'prop-types';
import {withStyles} from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import mainLogo from'../pics/Polariks_Logo_text.png';
import FloatingActionButtons from "./Buttons";
import Icon from '@material-ui/core/Icon';
import Fab from '@material-ui/core/Fab';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import ExitToApp from '@material-ui/icons/ExitToApp';
const styles = {
  root: {
    flexGrow: 1
  },
  grow: {
    flexGrow: 1
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20
  },
  picSize:{
    height:25,

     }
};



function ButtonAppBar(props) {
  const {classes} = props;



  return (<div className={classes.root}>
    <AppBar position="static">
      <Toolbar>
        <img  className={classes.picSize} src={mainLogo} alt="Loading..."/> <span>&nbsp;&nbsp;</span>
        <Typography variant="h6" color="inherit" className={classes.grow}>

        </Typography>

        {
          props.user
            ? <div><Fab onClick={props.logout} color="default" size="small" aria-label="exit" className={classes.fab}><ExitToApp/></Fab><span>&nbsp;&nbsp;</span>
            <Fab href="/summary" color="default" size="small" aria-label="summary" className={classes.fab}>
                <ViewModuleIcon/>
              </Fab></div>
            : <Button color="inherit" href="/login">Login</Button>
        }
        {
          props.user
            ? <FloatingActionButtons manage={'manage_devices_link'} />
            : null
          }
          <Fab href="/about" color="default" size="small" aria-label="manage" className={classes.fab}>
              <Icon>help</Icon>
            </Fab>

      </Toolbar>
    </AppBar>
  </div>);
}

ButtonAppBar.propTypes = {
  classes: PropTypes.object.isRequired
};

export default withStyles(styles)(ButtonAppBar);
