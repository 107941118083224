import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles
} from '@material-ui/core/styles';
import Slider from '@material-ui/lab/Slider';
const styles = {
  root: {
    width: 225,
    display: 'inline-flex',
    color: '#fff'
  },
  slider: {

    padding: '22px 0px',

  },

  colorTrack: {

    backgroundColor: '#7d97ce'
  },

  holdvalue: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',

  },
  text: {
    color: '#fff'
  }
};

class StepSlider extends React.Component {
  constructor(props) {
    super(props)
    this.datapoints = 'datapoints';
    this.state = {
      value: 1,
      max: this.props.limit
    }
  }


  handleChange = (event, value) => {
    this.setState({
      value
    });
    this.props.atChange(this.datapoints, this.state.value);
  };




  render() {
    const {
      classes
    } = this.props;
    const {
      value
    } = this.state;

    return (
      <div>


      <div className={classes.root}>


      <sub className={classes.text}>
        1 </sub>

        <Slider
          classes={{ container: classes.slider, track: classes.colorTrack  }}
          value={value}
          min={1}
          max={this.state.max}
          step={1}
          onChange={this.handleChange}

        />
        <sub className={classes.text}>
        {this.state.max}</sub>
        </div>
      </div>
    );
  }
}

StepSlider.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(StepSlider);
