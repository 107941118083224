import React, {
  Component
} from "react";
import {
  connect
} from "react-redux";
import {
  measurements,
  devices,
  auth
} from "../actions";
import history from '../history';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import ButtonAppBar from "./NavBar";
import NDVIChart from "./ndviChart";
import ControlledOpenSelect from "./ndviChartDropdown";
import SelectImage from "./selectImage"
import DialogSelect from "./dashboardAdvancedDialog";
import ImageView from "./imageview";

import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles
} from '@material-ui/core/styles';
import Helmet from 'react-helmet';
import Grid from '@material-ui/core/Grid';
import Query from './query'

const styles = theme => ({
  root: {
    flexGrow: 1,

  },
  fab: {
    margin: theme.spacing.unit
  },
  paper: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },



});
const theme = createMuiTheme({
  palette: {
    type: 'dark', // Switching the dark mode on is a single property value change.
    primary: {
      light: '#7d97ce',
      main: '#00043d',
      dark: '#5b79bb',
      contrastText: '#fff',
    },
    secondary: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#fff',
    }
  },

  typography: {
    useNextVariants: true
  },
});

class Dashboard extends Component {
  constructor(props) {
    super(props)
    this.handleChartSelection = this.handleChartSelection.bind(this)
    this.handleImageSelection = this.handleImageSelection.bind(this)
    this.state = {
      callType: 'datapoints',
      callValue: 400,
      autoUpdate: false,
      counter: 60,
      imageType: 'ndvi',
      open: false
    }
  }
  shouldComponentUpdate(nextProps, nextState) {

    // if there is a change in measurements, rerender
    if (nextProps.measurements.data !== this.props.measurements.data || nextProps.image.data !== this.props.image.data ||
      nextProps.measurementsChl.dataChl !== this.props.measurementsChl.dataChl) {
      return true
    } else {


      return false
    }
  }

  componentDidMount() {
    if (this.props.location.deviceId !== undefined && this.props.location.user !== undefined && this.props.location.logout !== undefined) {
      console.log("Mounted device: ")
      console.log(this.props.location.deviceId)
      // call the API

      this.props.getMeasurementsDefault(this.props.location.deviceId, this.state.callType, this.state.callValue);
      this.props.getMeasurementsChl(this.props.location.deviceId, this.state.callType, this.state.callValue);
      this.props.getImage(this.props.location.deviceId, this.state.imageType);

    }
    // if there is no device passed to this function go back to home
    else {
      history.push({
        pathname: "/"
      })
    }
  }



  //handle chart data
  handleChartSelection(call_type, call_value) {
    // call_type and call_value are returned by the child component ControlledOpenSelect
    // we pass the results into the API caller
    this.setState({
      callType: call_type,
      callValue: call_value
    }, function() {
      this._handleSubmit();
    });
  }

  //handle image dropdown
  handleImageSelection(image_value) {
    this.setState({
      imageType: image_value,
    }, function() {
      this._handleSubmitImage()
    });

  }


  _handleSubmit() {
    // go back to initialState for redux
    this.props.clearState()
    this.props.clearIMG()
    this.props.clearStateChl()
    this.props.getMeasurementsDefault(this.props.location.deviceId, this.state.callType, this.state.callValue)
    this.props.getMeasurementsChl(this.props.location.deviceId, this.state.callType, this.state.callValue);
    this.props.getImage(this.props.location.deviceId, this.state.imageType);
  }

  _handleSubmitImage() {
    this.props.clearIMG()
    this.props.getImage(this.props.location.deviceId, this.state.imageType);

  }



  render() {
    const {
      classes
    } = this.props;


    if (this.props.location.deviceId !== undefined && this.props.location.user !== undefined && this.props.location.logout !== undefined) {
      return (<div className={classes.root}>
      <MuiThemeProvider theme={theme}>
        <Helmet bodyAttributes={{
            style: 'background-color : #303030'
          }}/>
        <ButtonAppBar user={this.props.location.user} logout={this.props.location.logout}/>

        <br/>
        <Grid container spacing={16} style={{
    margin: 0,

    width: '100%',
  }}>
          <Grid item xs={12} sm={6} >
            <Card >
              <CardContent>
                <Typography variant="h5" gutterBottom component="h2" style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center'
                  }}>
                History
                </Typography>
                <hr/>
                <Typography component="div">

                  <DialogSelect user={this.props.user} logout={this.props.logout} devices={this.props.location.devices} atChange={this.handleChartSelection.bind(this)} limit={86400} disable={this.props.APIdataState}/>
                  <ControlledOpenSelect atChange={this.handleChartSelection.bind(this)} limit={false} disable={this.props.APIdataState}/>
                  {/*verify if the data is ready by checking the isloaded variable*/}
                  {this.props.APIdataState && this.props.APIchlState ?
                  <NDVIChart dataSet={this.props.measurements} readyness={this.props.APIdataState} chlDataSet={this.props.measurementsChl}
                  chlReadyness={this.props.APIchlState} classes={this.props.classes}/>
                  :
                  <NDVIChart dataSet={this.props.measurements} readyness={this.props.APIdataState}
                   classes={this.props.classes}/>
                }
                </Typography>

              </CardContent>

            </Card>

            <br/>
            <br/>
            <Card >
            <Query callType={this.state.callType} imageType={this.state.imageType} APIState={this.props.APIdataState} callValue={this.state.callValue} selectedDevice={this.props.location.deviceId}/>

            </Card>
          </Grid>
          <Grid item xs={12} sm={6}>
          <Card className={classes.card} >
          <CardContent>
          <Typography  variant="h5" gutterBottom component="h2" style={{display: 'flex',  justifyContent:'center', alignItems:'center'}}>
                    Last processed image

          </Typography>
            <hr/>


            {/*verify if the data is ready by checking the isloaded variable*/}
            {this.props.APIimgState ?
          <ImageView imageData={this.props.image} classes={this.props.classes} readyness={this.props.APIimgState}>
          <SelectImage disable={this.props.APIdataState}
          imageType={this.state.imageType} open={this.state.open}
          atChange={this.handleImageSelection.bind(this)}/> </ImageView>
          :
          <ImageView imageData={this.props.image} classes={this.props.classes} readyness={this.props.APIimgState}>
          <SelectImage disable={this.props.APIdataState}
          imageType={this.state.imageType} open={this.state.open}
          atChange={this.handleImageSelection.bind(this)}/> </ImageView>
          }

          </CardContent>

          </Card>
          </Grid>
        </Grid>

      </MuiThemeProvider>
    </div>)
    } else {
      return (<div>Going back to manage devices</div>)
    }
  }

}


const mapStateToProps = state => {

  return {
    measurements: state.measurements,
    measurementsChl: state.measurementsChl,
    user: state.auth.user,
    image: state.image,
    APIdataState: state.measurements.isLoaded,
    APIimgState: state.image.isLoaded,
    APIchlState: state.measurementsChl.isLoaded,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchDevices: () => {
      dispatch(devices.fetchDevices());
    },
    getMeasurementsDefault: (deviceId, call_type, call_value) => {
      return dispatch(measurements.getMeasurementsDefault(deviceId, call_type, call_value));
    },
    getMeasurementsChl: (deviceId, call_type, call_value) => {
      return dispatch(measurements.getMeasurementsChl(deviceId, call_type, call_value));
    },
    getImage: (deviceId, imageType) => {
      return dispatch(measurements.getImage(deviceId, imageType));
    },
    clearIMG: () => {
      return dispatch(measurements.clearIMGDataSets());
    },
    clearState: () => {
      return dispatch(measurements.clearDataSets());
    },
    clearStateChl: () => {
      return dispatch(measurements.clearDataSetsChl());
    },
    logout: () => dispatch(auth.logout())
  };

}

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Dashboard));
