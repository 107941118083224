import React, {Component} from 'react';
import {Route,  Router} from 'react-router-dom';
import {createStore, applyMiddleware} from "redux";
import thunk from "redux-thunk";
import history from './history';

import 'typeface-lato';
// import logo from './logo.svg';
import './App.css';
import {Provider, connect} from "react-redux";
import MainApp from "./components/MainApp";
import NotFound from "./components/NotFound";
import Login from "./components/Login";
import mainApp from './reducers';
import {auth} from "./actions";
//import Register from "./components/Register";
import Dashboard from "./components/dashboard";
import Summary from "./components/summaryDashboard";
import About from "./components/about";
import Switch from 'react-router-transition-switch'
import Fader from 'react-fader'

let store = createStore(mainApp, applyMiddleware(thunk));

class RootContainerComponent extends Component {

    shouldComponentUpdate(nextProps, nextState) {

    if (!this.props.auth.token || nextProps.auth.isAuthenticated === false){
      history.push({pathname: "/login"})
      return true

    }
    else {

    return true
  }

  }
  componentDidMount() {
    this.props.loadUser();


  }

  PrivateRoute = ({
    component: ChildComponent,
    ...rest
  }) => {
    return <Route {...rest} render={props => {
        if (this.props.auth.isLoading) {
          return <em>Loading...</em>;
        } else if (!this.props.auth.isAuthenticated) {
          return null
        } else {
          return <ChildComponent {...props}/>
        }
      }}/>
  }
  render() {


    let {PrivateRoute} = this;
    return (<Router history={history}>
      <Switch component={Fader} >
        <PrivateRoute exact path="/" component={MainApp}/>
        {/*<Route exact path="/register" component={Register}/>*/}
        <Route exact path="/about" component={About} auth={this.props.auth}/>
        <Route exact path="/login" component={Login}/>
        <PrivateRoute exact path="/dashboard" component={Dashboard}/>
        <PrivateRoute exact path="/summary" component={Summary}/>
        <Route component={NotFound}/>
      </Switch>
    </Router>);
  }
}

const mapStateToProps = state => {
  return {auth: state.auth}
}

const mapDispatchToProps = dispatch => {
  return {
    loadUser: () => {
      return dispatch(auth.loadUser());
    }
  }
}

let RootContainer = connect(mapStateToProps, mapDispatchToProps)(RootContainerComponent);

export default class App extends Component {
  render() {
    return (<Provider store={store}>
      <RootContainer/>
    </Provider>)
  }
}
