import React from 'react';
import PropTypes from 'prop-types';
import {
  withStyles
} from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';


const styles = theme => ({
  button: {
    display: 'block',
    marginTop: theme.spacing.unit * 2,
  },
  formControl: {
    margin: theme.spacing.unit,
    minWidth: 120,

  },
  form:{
    maxHeight: 200,
  }
});

class SelectImage extends React.Component {
  constructor(props) {
    super(props);
      // inherit imageType and open from dashboard
      this.state = {...props};
    }
  handleChange = event => {
    // call parent component with the results OUTCOMMENTED FOR NOW
    this.props.atChange(event.target.value)
    this.setState({
      [event.target.name]: event.target.value
    });
  };

  handleClose = () => {
    this.setState({
      open: false
    });
  };

  handleOpen = () => {
    this.setState({
      open: true
    });
  };

  render() {
    const {
      classes
    } = this.props;
    return (
      <form autoComplete="off" className={classes.form}>

        <FormControl className={classes.formControl} disabled={!this.props.disable}>
          <InputLabel htmlFor="controlled-open-select">Image Type</InputLabel>
          <Select
            open={this.state.open}
            onClose={this.handleClose}
            onOpen={this.handleOpen}
            value={this.state.imageType}
            onChange={this.handleChange}
            inputProps={{
              name: "imageType",
              id: 'controlled-open-select',
            }}
          >

            <MenuItem value={'ndvi'}>NDVI</MenuItem>
            <MenuItem value={'chl'}>Chlorophyll</MenuItem>
            <MenuItem value={'avgNdvi'}>Average NDVI</MenuItem>
            <MenuItem value={'avgChl'}>Average Chlorophyll</MenuItem>

          </Select>
        </FormControl>
      </form>
  )
  }


}

SelectImage.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(SelectImage);
